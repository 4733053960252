import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Pipy 内置了可视化 web 开发环境，包含了编辑器和图形化的辅助设计工具，用于简化脚本的开发和调试。`}</p>
    <h2 {...{
      "id": "启动"
    }}>{`启动`}</h2>
    <p>{`控制台的开启很简单，前面介绍 `}<a parentName="p" {...{
        "href": "/operating/cli/"
      }}>{`Pipy CLI`}</a>{` 时有介绍 `}<inlineCode parentName="p">{`--admin-port`}</inlineCode>{` 参数。`}</p>
    <p>{`启动 Pipy 时添加该参数：`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ pipy --admin-port=6060 tutorial/01-hello/hello.js
2021-10-09 08:40:48 [info] [config]
2021-10-09 08:40:48 [info] [config] Module /hello.js
2021-10-09 08:40:48 [info] [config] ================
2021-10-09 08:40:48 [info] [config]
2021-10-09 08:40:48 [info] [config]  [Listen on :::8080]
2021-10-09 08:40:48 [info] [config]  ----->|
2021-10-09 08:40:48 [info] [config]        |
2021-10-09 08:40:48 [info] [config]       serveHTTP
2021-10-09 08:40:48 [info] [config]        |
2021-10-09 08:40:48 [info] [config]  <-----|
2021-10-09 08:40:48 [info] [config]
2021-10-09 08:40:48 [info] [config]  [Listen on :::8081]
2021-10-09 08:40:48 [info] [config]  ----->|
2021-10-09 08:40:48 [info] [config]        |
2021-10-09 08:40:48 [info] [config]       demuxHTTP
2021-10-09 08:40:48 [info] [config]        |
2021-10-09 08:40:48 [info] [config]        |--> [tell-my-port]
2021-10-09 08:40:48 [info] [config]              replaceMessage -->|
2021-10-09 08:40:48 [info] [config]                                |
2021-10-09 08:40:48 [info] [config]  <-----------------------------|
2021-10-09 08:40:48 [info] [config]
2021-10-09 08:40:48 [info] [config]  [Listen on :::8082]
2021-10-09 08:40:48 [info] [config]  ----->|
2021-10-09 08:40:48 [info] [config]        |
2021-10-09 08:40:48 [info] [config]       demuxHTTP
2021-10-09 08:40:48 [info] [config]        |
2021-10-09 08:40:48 [info] [config]        |--> [tell-my-port]
2021-10-09 08:40:48 [info] [config]              replaceMessage -->|
2021-10-09 08:40:48 [info] [config]                                |
2021-10-09 08:40:48 [info] [config]  <-----------------------------|
2021-10-09 08:40:48 [info] [config]
2021-10-09 08:40:48 [info] [listener] Listening on port 8080 at ::
2021-10-09 08:40:48 [info] [listener] Listening on port 8081 at ::
2021-10-09 08:40:48 [info] [listener] Listening on port 8082 at ::
2021-10-09 08:40:48 [info] [codebase] Starting codebase service...
2021-10-09 08:40:48 [info] [listener] Listening on port 6060 at ::
`}</code></pre>
    <p>{`命令输出中说明了 GUI 监听在 `}<inlineCode parentName="p">{`6060`}</inlineCode>{` 端口，浏览器打开 `}<inlineCode parentName="p">{`http://localhost:6060`}</inlineCode>{`：`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/operating/console.png",
        "alt": null
      }}></img></p>
    <ol>
      <li parentName="ol">{`文件系统：展示当前加载的脚本所在目录，可以对文件进行操作`}</li>
      <li parentName="ol">{`展示当前监听的所有接口`}</li>
      <li parentName="ol">{`日志`}</li>
      <li parentName="ol">{`添加文件`}</li>
      <li parentName="ol">{`将文件设置为入口脚本`}</li>
      <li parentName="ol">{`删除文件`}</li>
      <li parentName="ol">{`保存修改`}</li>
      <li parentName="ol">{`从选定的入口脚本启动`}</li>
      <li parentName="ol">{`停止`}</li>
      <li parentName="ol">{`重新加载`}</li>
      <li parentName="ol">{`打开/关闭日志面板`}</li>
      <li parentName="ol">{`文件列表`}</li>
      <li parentName="ol">{`脚本编辑器`}</li>
      <li parentName="ol">{`图形化辅助设计工具：展示流量的处理流程`}</li>
      <li parentName="ol">{`日志面板`}</li>
    </ol>
    <h2 {...{
      "id": "脚本调试"
    }}>{`脚本调试`}</h2>
    <p>{`对于脚本的编写，可以参考 `}<a parentName="p" {...{
        "href": "/reference/api/Configuration"
      }}>{`内置 API`}</a>{` 以及 `}<a parentName="p" {...{
        "href": "/tutorial/01-hello/"
      }}>{`教程`}</a>{`。`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/operating/debug.png",
        "alt": null
      }}></img></p>
    <ol>
      <li parentName="ol">{`修改响应内容：返回 `}<inlineCode parentName="li">{`Hi!`}</inlineCode></li>
      <li parentName="ol">{`保存修改`}</li>
      <li parentName="ol">{`重新加载`}</li>
    </ol>
    <p>{`原来的 `}<inlineCode parentName="p">{`8080`}</inlineCode>{` 端口会`}<a parentName="p" {...{
        "href": "/getting-started/quick-start"
      }}>{`返回请求的内容`}</a>{`，按照上面的操作修改了脚本之后我们还是使用相同的命令：`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -x POST http://localhost:8080 -d 'hello world'
Hi!
`}</code></pre>
    <p>{`此时，返回了我们期望的内容 `}<inlineCode parentName="p">{`Hi!`}</inlineCode>{`。同时，修改后的内容也会实时反映到文件系统中。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      